import tire_1 from 'assets/images/tire/marca/logo-1.png'
import tire_2 from 'assets/images/tire/marca/logo-2.png'
import tire_3 from 'assets/images/tire/marca/logo-3.png'
import tire_4 from 'assets/images/tire/marca/logo-4.png'
import tire_5 from 'assets/images/tire/marca/logo-5.png'
import tire_6 from 'assets/images/tire/marca/logo-6.png'
import tire_7 from 'assets/images/tire/marca/logo-7.png'
import tire_8 from 'assets/images/tire/marca/logo-8.png'
import tire_9 from 'assets/images/tire/marca/logo-9.png'
import tire_10 from 'assets/images/tire/marca/logo-10.png'
import tire_11 from 'assets/images/tire/marca/logo-11.png'
import tire_12 from 'assets/images/tire/marca/logo-12.png'
import tire_13 from 'assets/images/tire/marca/logo-13.png'

import aptany_logo from 'assets/images/tire/brands/aptany.png'
import ardent_logo from 'assets/images/tire/brands/ardent.png'
import dunlop_logo from 'assets/images/tire/brands/dunlop.png'
import falken_logo from 'assets/images/tire/brands/falken.png'
import sumitomo_logo from 'assets/images/tire/brands/sumitomo.png'
import zextour_logo from 'assets/images/tire/brands/zextour.jpg'
import blackarror_logo from 'assets/images/tire/brands/blackarror.png'
import crossleader_logo from 'assets/images/tire/brands/crossleader.jpg'
import doublestar_logo from 'assets/images/tire/brands/doublestar.png'
import firemax_logo from 'assets/images/tire/brands/firemax.jpg'
import fullrun_logo from 'assets/images/tire/brands/fullrun.png'
import goodtrip_logo from 'assets/images/tire/brands/goodtrip.jpg'
import habilead_logo from 'assets/images/tire/brands/habilead.png'
import invovic_logo from 'assets/images/tire/brands/invovic.jpg'
import kapsen_logo from 'assets/images/tire/brands/kapsen.png'
import keter_logo from 'assets/images/tire/brands/keter.png'
import mazzini_logo from 'assets/images/tire/brands/mazzini.jpg'
import mileking_logo from 'assets/images/tire/brands/mileking.png'
import mirage_logo from 'assets/images/tire/brands/mirage.png'
import nereus_logo from 'assets/images/tire/brands/nereus.png'
import othsu_logo from 'assets/images/tire/brands/othsu.png'
import roadx_logo from 'assets/images/tire/brands/roadx.png'
import royal_black_logo from 'assets/images/tire/brands/royal_black.png'
import sumaxx_logo from 'assets/images/tire/brands/sumaxx.png'
import wanli_logo from 'assets/images/tire/brands/wanli.jpg'
import westlake_logo from 'assets/images/tire/brands/West-lake.png'
import kuhmo_logo from 'assets/images/tire/brands/Kumho.png'
import bridgestone_logo from 'assets/images/tire/brands/bridgestone.png'
import firestone_logo from 'assets/images/tire/brands/firestone.png'
import yokohama_logo from 'assets/images/tire/brands/Yokohama.png'
import general_logo from 'assets/images/tire/brands/General.png'
import cooper_logo from 'assets/images/tire/brands/Cooper.png'
import mickey_thompson_logo from 'assets/images/tire/brands/Mickey-thompson.png'
import dick_cepek_logo from 'assets/images/tire/brands/Dick-cepek.png'
import michelin_logo from 'assets/images/tire/brands/michelin.png'
import goodyear_logo from 'assets/images/tire/brands/good-year.png'
import bfgoodrich_logo from 'assets/images/tire/brands/bfgoodrich.png'


export const listWidth = [
	175,
	185,
	195,
	215,
	245,
	205,
	500,
	145,
	155,
	265,
	165,
	235,
	275,
	27,
	31,
	225,
	255,
	285,
	550,
	33,
	35,
	305,
	250,
	300,
	315,
	295,
	32,
	290,
	240,
	220,
	100,
	180
]

export const listProfile = [
	70,
	65,
	50,
	75,
	55,
	'R',
	45,
	60,
	8.50,
	10.50,
	40,
	35,
	12.50,
	640,
	680,
	11.50,
	675,
	570,
	62,
	550
]

export const listRing = [
	13,
	14,
	15,
	16,
	12,
	17,
	18,
	20,
	19,
	22,
	21
]


export const listTires = [
	//{ id: 1, title: "Neumático 1", imagen: tire_1 },
	{ id: 2, title: "Neumático 2", imagen: tire_2 },
	{ id: 3, title: "Neumático 3", imagen: tire_3 },
	{ id: 4, title: "Neumático 4", imagen: tire_4 },
	//{ id: 5, title: "Neumático 5", imagen: tire_5 },
	{ id: 6, title: "Neumático 6", imagen: tire_6 },
	{ id: 7, title: "Neumático 7", imagen: tire_7 },
	{ id: 8, title: "Neumático 8", imagen: tire_8 },
	{ id: 9, title: "Neumático 10", imagen: tire_9 },
	{ id: 10, title: "Neumático 10", imagen: tire_10 },
	{ id: 11, title: "Neumático 10", imagen: tire_11 },
	{ id: 12, title: "Neumático 10", imagen: tire_12 },
	//{ id: 13, title: "Neumático 10", imagen: tire_13 },
]

export const listBrandTire = [
	{ id: 1, title: 'APTANY', image: aptany_logo },
	{ id: 2, title: 'ARDENT', image: ardent_logo },
	{ id: 3, title: 'BLACKARROW', image: blackarror_logo },
	{ id: 4, title: 'BRIGHTWAY', image: '' },
	{ id: 5, title: 'CROSSLEADER', image: crossleader_logo },
	{ id: 6, title: 'DOUBLE KING', image: '' },
	{ id: 7, title: 'DOUBLESTAR', image: doublestar_logo },
	{ id: 8, title: 'DUNLOP', image: dunlop_logo },
	{ id: 9, title: 'DUNLOP COMPETICION', image: dunlop_logo},
	{ id: 10, title: 'FALKEN', image: falken_logo },
	{ id: 11, title: 'FIREMAX', image: firemax_logo},
	{ id: 12, title: 'FULLRUN', image: fullrun_logo},
	{ id: 13, title: 'GOODTRIP', image: goodtrip_logo },
	{ id: 14, title: 'HABILEAD', image: habilead_logo},
	{ id: 15, title: 'INVOVIC', image: invovic_logo},
	{ id: 16, title: 'KAPSEN', image: kapsen_logo},
	{ id: 17, title: 'KETER', image: keter_logo},
	{ id: 18, title: 'LONGWAY', image: ''},
	{ id: 19, title: 'LUISTONE', image: ''},
	{ id: 20, title: 'MAZZINI', image: mazzini_logo},
	{ id: 21, title: 'MILEKING', image: mileking_logo},
	{ id: 22, title: 'MIRAGE', image: mirage_logo },
	{ id: 23, title: 'NEREUS', image: nereus_logo},
	{ id: 24, title: 'OHTSU', image: othsu_logo},
	{ id: 25, title: 'PACIFIC TIRES', image: ''},
	{ id: 26, title: 'PALLYKING', image: ''},
	{ id: 27, title: 'ROADX', image: roadx_logo},
	{ id: 28, title: 'ROYAL BLACK', image: royal_black_logo},
	{ id: 29, title: 'STMOTORS', image: ''},
	{ id: 30, title: 'SUMAXX', image: sumaxx_logo},
	{ id: 31, title: 'SUMITOMO', image: sumitomo_logo },
	{ id: 32, title: 'TIANFU', image: ''},
	{ id: 33, title: 'WANLI', image: wanli_logo},
	{ id: 34, title: 'ZEXTOUR', image: zextour_logo },

	{ id: 35, title: 'WESTLAKE', image: westlake_logo },
	{ id: 36, title: 'KUHMO', image: kuhmo_logo },
	{ id: 37, title: 'BRIDGESTONE', image: bridgestone_logo },
	{ id: 38, title: 'FIRESTONE', image: firestone_logo },
	{ id: 39, title: 'YOKOHAMA', image: yokohama_logo },
	{ id: 40, title: 'GENERAL', image: general_logo },
	{ id: 41, title: 'COOPER', image: cooper_logo },
	{ id: 42, title: 'MICKEY-THOMPSON', image: mickey_thompson_logo },
	{ id: 43, title: 'DICK-CEPEK', image: dick_cepek_logo },

	{ id: 44, title: 'MICHELIN', image: michelin_logo },
	{ id: 45, title: 'GOODYEAR', image: goodyear_logo },
	{ id: 46, title: 'BFGOODRICH', image: bfgoodrich_logo }


]